// Global constants for the whole app
import {
  ANALYTICS_ANONYMOUS_USER,
  getProductionEnvironment,
  // eslint-disable-next-line tree-shaking/no-side-effects-in-initialization
  guardNever,
} from "@hex/common";
import { LDClient, LDContext, initialize } from "launchdarkly-js-client-sdk";

import hexLaunchDarklyProvider, {
  ALWAYS_RERENDER,
} from "./components/launchdarkly/hexLaunchDarklyProvider.js";
import { ORG_ID } from "./orgs.js";
import { launchDarklyDisabled } from "./util/data.js";
import { logErrorMsg } from "./util/logging";

declare global {
  interface Window {
    launchDarklyContext?: LDContext;
    // LDFlagsState is only exported on node sdk
    launchDarklyFlagState?: object;
  }
}

const LD_KEYS = {
  PROD: "5eab05c293bb130a701e54f0",
  STAGING: "5f48047f1f3feb08da94207b",
  DEVELOP: "5f4805a3ca98f809561d152b",
  LOCAL: "5eab05c293bb130a701e54ef",
  UNKNOWN: "5eab51ebcfea720a4dd058ac",
};

function getLDClientId(): string {
  const isProduction = process.env.NODE_ENV === "production";
  if (!isProduction) {
    return LD_KEYS.LOCAL;
  }

  const prodEnv = getProductionEnvironment(window.location.hostname);
  switch (prodEnv) {
    case "prod":
      return LD_KEYS.PROD;
    case "staging":
      return LD_KEYS.STAGING;
    case "develop":
      return LD_KEYS.DEVELOP;
    case "local":
      return LD_KEYS.LOCAL;
    // TODO: create new env for these and use them instead
    case "single-tenant":
    case "self-hosted":
    case "unknown":
      return LD_KEYS.UNKNOWN;
    default: {
      const err = guardNever(prodEnv, prodEnv);
      logErrorMsg(err, "Unrecognized production environment for LD client ID");
      return LD_KEYS.UNKNOWN;
    }
  }
}

// Needs to be kept in sync with server.ts
const LD_CLIENT_ID = getLDClientId();
const DEFAULT_CONTEXT: LDContext = {
  kind: "multi",
  user: {
    key: ANALYTICS_ANONYMOUS_USER.id,
    name: ANALYTICS_ANONYMOUS_USER.name,
    email: ANALYTICS_ANONYMOUS_USER.email,
  },
  organization: {
    key: ORG_ID,
  },
};

const LD_CONTEXT = window.launchDarklyContext ?? DEFAULT_CONTEXT;
let LD_CLIENT: LDClient | undefined;

export function getLdClient(): LDClient | undefined {
  if (LD_CLIENT == null && !launchDarklyDisabled) {
    LD_CLIENT = initialize(LD_CLIENT_ID, LD_CONTEXT, {
      // we disable client side events since we were rely on our own `Feature Flag
      // Evaluated` event instead.
      sendEventsOnlyForVariation: false,
      bootstrap: window.launchDarklyFlagState,
    });
  }
  return LD_CLIENT;
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export async function buildLaunchDarklyProvider(ldClient: LDClient) {
  return await hexLaunchDarklyProvider({
    // Force consumer to pass it in just to verify that it exists
    ldClient,
    timeout: 1,
    liveUpdateConfig: {
      "auto-refresh-client-config": {
        shouldRerender: ALWAYS_RERENDER,
      },
    },
  });
}
